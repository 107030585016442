<template>
  <div></div>
</template>

<script>
import util from '../../js/util'
import { mapState } from 'vuex'
import orderApi from '../../api/order'

export default {
    name: 'orderDetailView',
    data(){
        return {
            orderId: '',
            goodListByDeliverDate: [],
            order: null,
            loading: false,
        }
    },
    computed: {
        ...mapState('client', ['token']),
        contactName(){
            if(this.order == null) return ''
            return this.order.client.name
        },
        expectedLocationName(){
            if(this.order == null) return ''
            return this.order.shipping.office
        },
        payment(){
            if(this.order == null) return ''
            return this.order.payment
        },
        status(){
            if(this.order == null) return ''
            return this.order.status
        },
        orderDate(){
            if(this.order == null) return ''
            var date = new Date(this.order.CreatedAt)
            return util.dateFormat("YYYY-mm-dd HH:MM:SS", date)
        },
        orderTotal(){
            if(this.order == null) return '0.00'
            return (this.order.total/100).toFixed(2)
        },
        statusList(){
            if(this.order == null) return []
            return this.order.order_statues
        },
        receiptLink(){
            if(this.order == null) return '#'
            return '/receipt/' + this.order.ID
        }
    },
    mounted(){
        this.orderId = this.$route.params.orderId
        this.initOrder()
    },
    methods: {
        initOrder(){
            orderApi.getOrderById(this.token, this.orderId).then(resp=>{
                this.order = resp
                this.initGoodListByDeliverDate()
                this.loading = false
            }).catch(err=>{
                console.log(err)
                this.loading = false
            })
        },
        formatDate(dateStr){
            var date = new Date(dateStr)
            return util.dateFormat("YYYY-mm-dd HH:MM:SS", date)
        },
        initGoodListByDeliverDate() {
            var goodDateDict = {}
            this.order.items.forEach(good=>{
                console.log(good)
                var date = good.deliver_date
                if(date in goodDateDict) {
                    goodDateDict[date].push(good)
                } else {
                    goodDateDict[date] = [good]
                }
            })
            var list = []
            for(var key in goodDateDict){
                list.push({
                    deliverDate: key,
                    is_member_by: this.order.is_member_by,
                    goods: goodDateDict[key]
                })
            }
            console.log(list)
            this.goodListByDeliverDate = list
        },
        confirmDeliverClick(){
            this.loading = true
            orderApi.confirmDeliver(this.token, this.orderId).then(()=>{
                this.initOrder()
            }).catch(()=>{
                this.loading = false
            })
        },
        gotoReview(){
            window.location.href = '/review/' + this.orderId
        }
    }
}
</script>

<style>
.checkout-sub-div-4{
    width: 50%;
    text-align: left;
    padding: 46px 66px 0px 0px;
}
.checkout-sub-div-3{
    width: 50%;
    text-align: left;
    padding: 40px 0px 0px 40px;
}
.checkout-payment-info-column {
    display:-webkit-box;
    margin:10px;
    padding:10px;
    border-bottom:1px solid #DCDFE6;
    font-size: 14px;
}
.checkout-payment-info-column-2 {
    display:-webkit-box;
    margin:10px;
    padding:10px;
    font-size: 14px;
}
</style>