function dateFormat(fmt, date) {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}

var weeks = {
    "0": "Sun",
    "1": "Mon",
    "2": "Tues",
    "3": "Wed",
    "4": "Thur",
    "5": "Fri",
    "6": "Sat",
}

function getWeedDay(day){
    return weeks[day + '']
}

var months = {
    "0": "Jan",
    "1": "Feb",
    "2": "Mar",
    "3": "Apr",
    "4": "May",
    "5": "Jun",
    "6": "Jul",
    "7": "Aug",
    "8": "Sept",
    "9": "Oct",
    "10": "Nov",
    "11": "Dec",
}

function getMonth(month){
    return months[month + '']
}

function addDay(add) {
    var dateTime = new Date()
    dateTime = dateTime.setDate(dateTime.getDate() + add * 1)
    dateTime = new Date(dateTime)
    return dateTime
}

function getExpectedDeliverDay(add){
    var currentTime = new Date()
    if(currentTime.getHours() < 17){
        return addDay(add)
    } else {
        return addDay(add * 1 + 1)
    }
}

function checkTokenTimeExpired(tokenTime){
    var ts = new Date().getTime() - tokenTime
    console.log(ts)
    if(ts / 1000 > 3600 * 24 * 3) {
        return true
    }
    return false
}

export default {
    dateFormat,
    getExpectedDeliverDay,
    checkTokenTimeExpired,
    getWeedDay,
    getMonth,
}