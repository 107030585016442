<template>
  <base-mobile-page>
    <template v-slot:main>
        <el-main style="padding:40px 20px;margin:0 auto;width:100%;max-width:1300px;">
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 5px;">
            <el-breadcrumb-item to="/account">My Account</el-breadcrumb-item>
            <el-breadcrumb-item to="/orders">Order</el-breadcrumb-item>
            <el-breadcrumb-item>{{orderId}}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-row style="margin-top:20px;">
            <el-col :span="24" class="checkout-sub-div">
                <div style="border:1px solid #DCDFE6;border-radius:5px;text-align:left;">
                    <div class="checkout-payment-info-column">
                        <div style="width:120px;color:#737373;">Contact</div>
                        <div>{{contactName}}</div>
                    </div>
                    <div class="checkout-payment-info-column">
                        <div style="width:120px;color:#737373;">Ship to</div>
                        <div>{{expectedLocationName}}</div>
                    </div>
                    <div class="checkout-payment-info-column">
                        <div style="width:120px;color:#737373;">Payment</div>
                        <div>{{payment}}</div>
                    </div>
                     <div class="checkout-payment-info-column">
                        <div style="width:120px;color:#737373;">Status</div>
                        <div>{{status}}</div>
                    </div>
                    <div class="checkout-payment-info-column-2">
                        <div style="width:120px;color:#737373;">Date</div>
                        <div>{{orderDate}}</div>
                    </div>
                </div>
                <div style="margin-top:20px;display:-webkit-box;">
                    <div>
                        <el-popconfirm title="You have alreay get all goods in this order" @confirm="confirmDeliverClick">
                            <el-button :disabled="status == 'delivered'" slot="reference" type="danger" :loading="loading">Confirm Deliver</el-button>
                        </el-popconfirm>
                    </div>
                    <!--<div style="margin-left:20px;">
                        <el-button type="primary" @click="gotoReview">Write Review</el-button>
                    </div>-->
                </div>
                <div class="chdkout-sub-div-bottom">
                </div>
                <div>
                    <div style="display:-webkit-box;margin-top:20px;">
                        <el-link :href="receiptLink" target="_blank" type="primary">Get Receipt</el-link>
                        <div style="margin-right:10px;margin-left:10px;"><el-link href="/pages/privacy-policy">Privacy policy</el-link></div>
                        <div><el-link href="/pages/terms-of-service">Terms of service</el-link></div>
                    </div>
                     <el-divider></el-divider>
                </div>
            </el-col>
            <el-col :span="24" class="checkout-sub-div">
                <order-detail-item v-for="(item, index) in goodListByDeliverDate" :key="index" :item="item"></order-detail-item>
                <el-divider></el-divider>
                <div style="display:-webkit-box;font-size:14px;">
                  <div>Subtotal</div>
                  <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:14px;">
                      <span>${{orderTotal}}</span>
                  </div>
                </div>
                <div style="display:-webkit-box;margin-top:10px;font-size:14px;">
                     <div>Shipping</div>
                     <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:14px;">
                         <span>Free</span>
                     </div>
                </div>
                <el-divider></el-divider>
                <div style="display:-webkit-box;">
                     <div>Total</div>
                     <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:24px;">
                         <span>${{orderTotal}}</span>
                     </div>
                </div>
            </el-col>
        </el-row>
      </el-main>
    </template>
  </base-mobile-page>
</template>

<script>
import BaseMobilePage from '../../components/base/BaseMobilePage.vue'
import ModelOrderDetail from '../viewmodel/ModelOrderDetail.vue'
import OrderDetailItem from '../../components/widgets/OrderDetailItem.vue'

export default {
    extends: ModelOrderDetail,
    name: 'orderDetailView',
    components: {
        BaseMobilePage,
        OrderDetailItem
    }
}
</script>

<style>
</style>