<template>
  <div id="orderDetailItem">
      <div style="font-size:14px;padding:0px 0px 15px 0px;display:-webkit-box;height:36px;line-height:36px;">Deliver at {{item.deliverDate}} </div>
      <div class="order-good-item" v-for="good in item.goods" :key="good.ID">
          <div>
            <el-badge :value="good.amount" type="primary">
                <el-image :src="good.product_image" fit="fit" style="width:64px;height:64px;border-radius:5px;border:1px solid #E4E7ED;"></el-image>
            </el-badge>
          </div>
          <div style="margin-left:20px;margin-top:15px;-webkit-box-flex:1;">
            <div style="font-size:16px;">{{good.product_name}}</div>
            <div style="margin-top:10px;color:grey;font-size:14px;">{{good.sku_name}}</div>
          </div>
          <div style="line-height:64px;text-align:right;font-weight:bold;font-size:14px;">
            <span v-if="item.is_member_by">${{(good.sub_member_total/100).toFixed(2)}}</span>
            <span v-else>${{(good.sub_total/100).toFixed(2)}}</span>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    name: 'orderDetailItem',
    props: ['item']
}
</script>

<style>
.order-good-item{
    display: -webkit-box;
    margin: 0px 0px 10px 0px;
}
</style>